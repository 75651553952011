import { jwtDecode } from "jwt-decode";

class apicarthandler{
    constructor(usernameinp,useridinp){
        this.BASE_URL=""
        this.username=usernameinp;
        this.userid=useridinp;
    }

    getCSRFToken = () => {
        const csrfCookie = document.cookie.match(/csrftoken=([^ ;]+)/);
        return csrfCookie ? csrfCookie[1] : '';
    };
      

    getmainproducts = async () =>{
        let response = await fetch("/api/apigetmainproducts",{
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            },
        });
        let data = await response.json();
        console.log(data);
        return data;
    }




    getcartproducts = async () =>{
        let response = await fetch("/api/apigetcartproducts",{
            method:'POST',
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': this.getCSRFToken(),
            },
        });
        let data = await response.json();
        return data;
    }

    
    getsessioncartproducts = async () =>{
        let response = await fetch("/api/apigetsessioncartdata",{
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            },
        });
        let data = await response.json();
        return data;
    }

    addsessioncartproduct = async (productid,size,unit)=>{
        let response = await fetch("/api/apiaddsessioncartdata",{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
            },
            body:JSON.stringify({
                'productid':productid,
                'size':size,
                'unit':unit
            })

        });
        let data = await response.json();
        return data;
    }


    deleteproduct = async (cartid,productid,size)=>{
        let response = await fetch("/api/apideletecartproduct",{
            method:'POST',
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': this.getCSRFToken(),
            },
            body:JSON.stringify({
                'cartid':cartid,
                'productid':productid,
                'productsize':size
            })

        });
        let data = await response.json();
        return data;
    }

    changeproductqty = async (cartid,productid,productsize,change) =>{
        let response = await fetch("/api/apichangecartsize",{
            method:'POST',
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': this.getCSRFToken(),
            },
            body:JSON.stringify({
                'cartid':cartid,
                'productid':productid,
                'productsize':productsize,
                'change':change
            })

        });
        let data = await response.json();
        return data;
    }

    getuseraddress = async ()=>{
        let response = await fetch("/api/apigetuseraddress",{
            method:'POST',
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': this.getCSRFToken(),
            },
            body:JSON.stringify({
                'userid':this.userid,
            })
        });
        let data = await response.json();
        return data;
    }

    adduseraddress = async (city,number,pincode,address1,address2,address,isdefault,name)=>{
        let response = await fetch("/api/apiadduseraddress",{
            method:'POST',
            headers:
            {
                'Content-Type':'application/json',
                'X-CSRFToken': this.getCSRFToken(),
            },
            body:JSON.stringify({
                'name':name,
                'city':city,
                'pincode':pincode,
                'number':number,
                'address1':address1,
                'address2':address2,
                'address':address,
                'default':isdefault
            })

        });
        let data = await response.json();
        return data;
    }
}


export default apicarthandler;